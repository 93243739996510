import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import { store } from "./store/index";
import "../src/assets/css/animate.min.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/font-awesome.min.css";
import "../src/assets/css/templatemo-style.css";

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
