import Vue from "vue";
import VueRouter from "vue-router";
import HomeOne from "../views/HomeOne.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeOne",
    component: HomeOne,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior(to) {
    document.getElementById("app").scrollIntoView();

    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
